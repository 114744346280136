
.demoreel-container {
    // background-color: var(--WHITE);
    padding-left: 1em;
    padding-right: 1em;
    display:  inline-flexbox;
    position: relative;
    min-height: 50vh;
    max-height:90vh;
    // margin: auto;
    background-image: url('../images/lime-wave.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    
}


.video {
    position: relative;
    min-height: 400px;
    // max-height: 1000px;
    // max-width: 1080px;
    padding-bottom: 10%;
    margin: auto;

}

.video iframe {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 100%;
}


@media only screen and (max-width: 800px) {
  // .demoreel-container {
  //     /* Set rules to fill background */
  //     min-height: 100%;
  //     min-width: 2560px;

  //     /* Set up proportionate scaling */
  //     width: 100%;    
  // }

  .demoreel-container {
    // background-color: var(--WHITE);
    padding-left: 1em;
    padding-right: 1em;
    padding-top:100px;
    padding-bottom:100px;
    text-align:  center;
    position: relative;
    justify-content: center;
    min-height: 20vh;
    max-height:50vh;
    // margin: auto;
    background-image: url('../images/lime-wave.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    
}
  
  .video {
    // position: relative;
    min-height: 200px;
    max-width: 650px;
    // max-height: 1000px;
    // max-width: 1080px;
    // padding-bottom: 10%;
    // margin: auto;

}
}