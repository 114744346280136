.Klaus {
    font-family: 'inter', sans-serif;
    width: 100%;
    height: 100vh;
}

.klaus-content {
    display: flex;
    flex-direction: column;
    margin: auto;

}

.intro-row {
    padding: 2em;
}

.klaus-left-col {
    text-align: center;
}
.tags {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.project-name {
    font-size: 24px;
    font-weight: bold;
}
.responsible-for {
    font-size: 14px;
    color:var(--DARKGREY);
    font-style: italic;
    font-weight: normal;
    margin-top: 1em;
    
}
.klaus-right-col {
    margin-top: 2em;
    font-size: 14px;
}

.klaus-background {
    background-image: url('../../images/dotted-wave.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.klaus-video {
    position:relative;
    min-height: 300px;
    max-width: 900px;
    // max-height: 1000px;
    // max-width: 1080px;
    padding-top: 10%;
    margin: auto;
}

.klaus-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
  }

.klaus-renders{
    margin: auto;
    margin-top: 1em;
    height:400px;
    display:grid;
    grid-template-columns: 50% 50%;
    grid-row: 50% 50%;
}

#klaus-one {
    background-image: url('../../images/Klaus/Klaus-01.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
#klaus-two {
    background-image:url('../../images/Klaus/Klaus-02.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
#klaus-three {
    background-image: url('../../images/Klaus/Klaus-03.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
#klaus-four {
    background-image: url('../../images/Klaus/Klaus-04.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

a.klaus-wiki {
    text-decoration: none;
    color: var(--BLACK);
    border-bottom: var(--MUSTARD) 0.125em solid;
}



@media only screen and (min-width: 720px) {

    .klaus-content {
        display: flex;
        flex-direction: column;
        // max-width: 800px;
        margin: auto;
        padding: 3em;
    }
    
    .intro-row {
        display: flex;
        flex-direction: row;
        max-width: 800px;
        margin: auto;
        column-gap: 0.3em
    ;
    
    }
    .klaus-left-col {
        width: 50%;
    }
    
    .klaus-name {
        font-size: 24px;
        text-align: left;
    
    }

    .responsible-for {
        text-align: left;   
    }

    .tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
    }
    .klaus-right-col {
        width: 50%;
        font-size: 14px;
        text-align: justify;
    }

    .klaus-renders{
        margin: auto;
        margin-top: 1em;
        padding: 4em;
        height:100%;
        display:grid;
        grid-template-columns: 50% 50%;
        grid-row: 50% 50%;
    }
}

