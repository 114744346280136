/*Fonts*/
@import url("https://use.typekit.net/sxs1bfl.css"); /*poppins*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); /*inter*/
* {
    margin: 0;
    padding: 0;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.Homepage::-webkit-scrollbar {
    display: none;
}

.Homepage {
    height: 100%;
    margin: 0;
    font-family: 'inter', arial;
    background-color: var(--WHITE);
    position: relative;
    z-index: 0;
    overflow-x: hidden;
}

.hero {
    // position: relative;
    // max-width: 900px;
    // margin: auto;
    width: 100%;
    height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homepage-left{
    width: 100%;
    text-align: left;
}

.homepage-right {
    width: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
}

.left {
    width: 100%;
    text-align: center;

    margin: auto;
}

.right {
    width: 100%;
}


h1 {
    padding-top: 0.5vh;
    color: var(--BLACK);
    font-size: 3em;
    text-align: center;
}

h2 {
    padding-top: 3vh;
    color: var(--BLACK);
    font-size: 24px;
    text-align: center;
}

.my-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

h1#fullstop {
    color: var(--MUSTARD);
    font-size: 2em;
    text-align:center;
}


.profile-image {
    height: 40vh;
    width: 40vh;
    border-radius: 50%;
justify-self: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    object-fit: cover;
}

.passions {
    padding: 5vh;
    position: relative;
    display: flex;
    margin: auto;
    margin-top: 1em;
    max-width: 500px;
    justify-content: space-around;
    flex-direction: row;
}

.passion {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.label {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 24px;
}

.art-icon {
    background-image: url('../images/PaintBrushBroad.png');
    width: 10vh;
    height: 10vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.cg-icon {
    background-image: url('../images/Cube.png');
    width: 10vh;
    height: 10vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.code-icon {
    background-image: url('../images/Code.png');
    width: 10vh;
    height: 10vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.mini-bio {
    position: relative;
    // max-width: 600px;
    text-align: center;
    font-size: 16px;
    margin-left: 2em;
    margin-right: 2em;
    padding-top: 3em;

}

a.hyperlink, a.hyperlink:link, a.hyperlink:visited{
    text-decoration: none;
    color: var(--BLACK);
    border-bottom: var(--MUSTARD) 0.125em solid;
}

a.hyperlink:hover {
    text-decoration: none;
    background-color: var(--MUSTARD);
    cursor: cell;
}



.arrow-anim {
    background-image: url('../images/CaretDoubleDown.png');
    width: 5vh;
    height: 5vh;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 3vh;
    animation: arrowAnim 1s infinite alternate-reverse;
}

@keyframes arrowAnim {
    from{
        transform:translate(0px, 10px);
    }
    to {
        transform: translate(0px, 40px);
    }
}

.binder {
    position: relative;
    background-image: url('../images/binder-loop-image.png');
    width: 50vh;
    height: 10vh;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    // margin-top: 5vh;
    margin-bottom: -2em;
    z-index: 1;
}

// GRAPHICS PROJECTS


.graphics-header {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    justify-content: space-between;

}

.graphics-title {
    font-size: 40px;
    font-weight: bold;
    color: var(--BLACK);
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    display:flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
}

.graphics-see-more {
    font-size: 16px;
    font-weight: bold;
    color: var(--BLACK);
    text-align: right;
    border-bottom: var(--MUSTARD) 0.125em solid;


}
.graphics-see-more:hover{
    background-color: var(--MUSTARD);
    cursor: cell;
}

// TECH CARDS



.tech-featured {
    font-size: 16px;
    font-weight: bold;
    color: var(--MUSTARD);
    max-width: 800px;
}

.tech-header {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    justify-content: space-between;
    margin-top: 2em;
}

.tech-title {
    font-size: 40px;
    font-weight: bold;
    color: var(--BLACK);
    padding-left: 1em;
    padding-right: 1em;
    display:flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
}

.tech-see-more {
    font-size: 16px;
    font-weight: bold;
    color: var(--BLACK);
    text-align: right;
    border-bottom: var(--MUSTARD) 0.125em solid;


}
.tech-see-more:hover{
    background-color: var(--MUSTARD);
    cursor: cell;
}




// ARTS CARDS



.arts-featured {
    font-size: 16px;
    font-weight: bold;
    color: var(--MUSTARD);
    max-width: 800px;
}

.arts-header {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    justify-content: space-between;
    margin-top: 2em;

}

.arts-title {
    font-size: 40px;
    font-weight: bold;
    color: var(--BLACK);
    padding-left: 1em;
    padding-right: 1em;
    display:flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
}

.arts-see-more {
    font-size: 16px;
    font-weight: bold;
    color: var(--BLACK);
    text-align: right;
    border-bottom: var(--MUSTARD) 0.125em solid;


}
.arts-see-more:hover{
    background-color: var(--MUSTARD);
    cursor: cell;
}


a.resume-button {
    text-decoration: none;
    width: 30%;
    border-bottom: var(--MUSTARD) 1em solid;
    font-weight: bold;
    color: var(--BLACK);
}

a.resume-button:hover {
    background-color: var(--MUSTARD);
    cursor: cell;
}

// ANIMATIONS


@media only screen and (min-width: 790px) {

    .content {
      flex-direction: row;
      margin: auto;
      height: 80%;
      max-width: 900px;
      padding-left: 32px;
      padding-right: 32px;
    }

    .hero {
      margin-left: auto;
      margin-right: auto;
    }

    .homepage-left {
      flex-grow: 1;
      width: auto;
    }

    .homepage-right {
      flex-shrink: 1;
      width: auto;
    }

    h1, h2, .mini-bio {
        text-align: left;
        margin: 0;
    }

    h1 {
        font-size: 100px;
    }
    h2 {
        font-size: 42px;
    }
    .passions {
        flex-direction: column;
    }

    .my-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}
