.ProceduralTree {
    font-family: 'inter', sans-serif;
    width: 100%;
    height: 100vh;
}

.tree-content {
    display: flex;
    flex-direction: column;
    margin: auto;

}

.intro-row {
    padding: 2em;
}

.tree-left-col {
    text-align: center;
}
.tags {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.tree-top-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1em;
}

.project-name {
    font-size: 24px;
    font-weight: bold;
}

a.tree-git-link {
    background-image: url('../../images/github-circle.png');
    height: 30px;
    width: 30px;
    background-size:contain;
    background-repeat: no-repeat;
}
.responsible-for {
    font-size: 14px;
    color:var(--DARKGREY);
    font-style: italic;
    font-weight: normal;
    margin-top: 1em;
    
}
.tree-right-col {
    margin-top: 2em;
    font-size: 14px;
}

.tree-background {
    background-image: url('../../images/dotted-wave.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.tree-video {
    width: 100%    !important;
    height: auto   !important;
    max-width: 800px;
    margin: auto;
    display: block;
}


.tree-details {
    margin:auto;
    max-width: 900px;
    margin-top: 1em;
}

.grammars, .instanced-rendering, .tree-parameters {
    padding: 2em;
    text-align: left;
}
.grammars-title, .instanced-title, .tree-parameters-title {
    font-size: 24px;
    border-left: var(--MUSTARD) 0.5em solid;
    padding-left: 1em;
}




@media only screen and (min-width: 720px) {

    .tree-content {
        display: flex;
        flex-direction: column;
        // max-width: 800px;
        margin: auto;
        padding: 3em;
    }

    .tree-top-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 1em;
    }
    
    .intro-row {
        display: flex;
        flex-direction: row;
        max-width: 800px;
        margin: auto;
        column-gap: 0.3em
    }
    .tree-left-col {
        width: 50%;
    }
    
    .project-name {
        font-size: 24px;
        text-align: left;
    
    }

    .responsible-for {
        text-align: left;   
    }

    .tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
    }
    .tree-right-col {
        width: 50%;
        font-size: 14px;
        text-align: justify;
    }
}
