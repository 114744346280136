.TinyTavern {
    font-family: 'inter', sans-serif;
    width: 100%;
    height: 100vh;
}

.tavern-content {
    display: flex;
    flex-direction: column;
    margin: auto;

}

.intro-row {
    padding: 2em;
}

.tavern-left-col {
    text-align: center;
}
.tags {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.project-name {
    font-size: 24px;
    font-weight: bold;
}
.responsible-for {
    font-size: 14px;
    color:var(--DARKGREY);
    font-style: italic;
    font-weight: normal;
    margin-top: 1em;
    
}
.tavern-right-col {
    margin-top: 2em;
    font-size: 14px;
}

.tavern-background {
    background-image: url('../../images/dotted-wave.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.tavern-video {
    width: 100%    !important;
    height: auto   !important;
    max-width: 800px;
    margin: auto;
    display: block;
}

.tavern-one {
    background-image: url('../../images/TinyTavern/TinyTavern01.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 800px;
    height: 275px;
    position: relative;
    margin:auto;
}
.tavern-two {
    background-image:url('../../images/TinyTavern/TinyTavern02.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 800px;
    height: 275px;
    position: relative;
    margin:auto;

}




@media only screen and (min-width: 720px) {

    .tavern-content {
        display: flex;
        flex-direction: column;
        // max-width: 800px;
        margin: auto;
        padding-top: 3em;
    }
    
    .intro-row {
        display: flex;
        flex-direction: row;
        max-width: 800px;
        margin: auto;
        column-gap: 0.3em
    ;
    
    }
    .tavern-left-col {
        width: 50%;
    }
    
    .tavern-name {
        font-size: 24px;
        text-align: left;
    
    }

    .responsible-for {
        text-align: left;   
    }

    .tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
    }
    .tavern-right-col {
        width: 50%;
        font-size: 14px;
        text-align: justify;
    }

    
    .tavern-one {
        max-width: 800px;
        height: 400px;
    }
    .tavern-two {
        max-width: 800px;
        height: 400px;
    }

}

