

.ProjectHub {
    font-family: 'inter', sans-serif;
    height: 100%;
    margin: 0;
    font-family: 'inter', arial;
    background-color: var(--WHITE);
    position: relative;
    z-index: 0;
}
.project-hub-header {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    max-width: 800px;
    padding-top: 2em;
    margin: auto;
}
#projects {
    margin-top: 1em;
}