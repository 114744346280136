:root {
    --ORANGE: #EE9F4A;
    --LIGHTGREEN: #DFEDDC;
    --DARKGREEN: #153A1E;
    --YELLOW: #F4C553;
    --PALE: #FCFAEB;
    --BLACK: #000000;
    --WHITE: #FFFFFF;
    --BLUE:#2c9dfa;
    --PALEBLUE:#daeeff;
    --LIMEGREEN: #d2ff3c;
    --DARKGREY:#2e2e2e;
    --LIGHTGREY:#c3c3c3;
    --MUSTARD: #E7B43A;
}